



























import { State } from '@/models/store';
import { socialService } from '@/services';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class IDeal extends Vue {
  @Getter private idealClientSecret!: State['idealClientSecret'];
  private paymentDisabled = true;

  private created() {
    if (!this.idealClientSecret) {
      this.$router.push({ name: 'ConfirmPage' });
    }
  }

  public async mounted() {
    const stripe = await loadStripe(window.config.stripePublishableKey, { locale: 'nl' });
    stripe && this.onStripeLoaded(stripe);
  }

  private onStripeLoaded(stripe: Stripe) {
    const elements = stripe.elements();

    const options = {
      // Custom styling can be passed to options when creating an Element
      style: {
        base: {
          padding: '10px 12px',
          color: '#32325d',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
      },
    };

    // Create an instance of the idealBank Element
    const idealBank = elements.create('idealBank', options);

    // Add an instance of the idealBank Element into
    // the `ideal-bank-element` <div>
    idealBank.mount('#ideal-bank-element');

    const form = document.getElementById('setup-form');

    this.paymentDisabled = false;

    form?.addEventListener('submit', async event => {
      event.preventDefault();

      // The following errors shouldn't normally occure.
      // Only when the user would reach the iDeal page manually (without going through our checkout flow).

      if (!this.idealClientSecret) {
        throw new Error('Missing iDeal Client Secret.');
      }

      this.paymentDisabled = true;
      const profile = await socialService.getUserProfile();
      if (!profile) {
        throw new Error('Missing user profile.');
      }

      const {
        email,
        profile: { firstName, lastName },
      } = profile;

      await stripe.confirmIdealSetup(this.idealClientSecret, {
        payment_method: {
          ideal: idealBank,
          // Needed to create a SEPA Debit payment method.
          billing_details: {
            name: `${firstName} ${lastName}`,
            email,
          },
        },
        return_url: `${window.config.baseUrlServer}/hooks/checkout-result`,
      });
    });
  }
}
